<template>
	<simple-loading
		:show-loading-card="true"
		:show-load-failed-card="true"
		:is-loading="state.isLoading"
		:is-load-failed="state.isLoadFailed"
	>
		<div
			class="container-fluid"
			v-if="Object.keys(state.operatorMap).length > 0"
		>
			<div class="row">
				<div class="col-12 mb-1 pr-0"  v-for="key in orderBy(Object.keys(state.operatorMap))" :key="key">
					<shopee-relabel-operator
						:operator="key"
						:data="state.operatorMap[key]"
						:lastUpdate="state.lastUpdate"
					/>
				</div>
			</div>
		</div>

		<div class="card" id="not-found-container" v-else>
			<img
				id="no-result-img"
				:src="require('@/assets/images/kerry/no-results.gif')"
			/>
		</div>
	</simple-loading>
</template>

<script>
	import { onMounted, ref, reactive } from "@vue/composition-api";
	import ShopeeRelabelOperator from "./ShopeeRelabelOperator.vue";

	import dayjs from "dayjs";
	var utc = require("dayjs/plugin/utc");
	dayjs.extend(utc);
	import apis from "@configs/apis";
	import { createWrapperWebSocket } from "@/utils/websocket-helper";
	import websocketApis from "@configs/ws-apis";
	import webSocketMessageTypes from "@configs/ws-message-types";
	import { fetcher, FetchMethod } from "@/libs/axios";
	import { getCurrentInstance } from "@vue/composition-api";
	import { formatNumber } from "@/utils/ui-helper";
	import { getLocalDateTimeString } from '@/utils/date-time-helper';
	import SimpleLoading from '@common-components/simple-loading/SimpleLoading.vue'
	import _ from 'lodash';

	export default {
		components: {
			ShopeeRelabelOperator,
			SimpleLoading,
		},
		setup() {
			const $toast = getCurrentInstance().proxy.$toast;
			const socketRef = ref(null);

			const state = reactive({
				operatorMap: {},
				lastUpdate: "-",
				isLoading: false,
				isLoadFailed: false,
			});

			async function fetchList() {
				try {
					state.isLoadFailed = false;
					state.isLoading = true;

					const response = await fetcher(
						apis.shopeeRelabel,
						FetchMethod.GET
					);

					state.operatorMap = response;
				} catch (error) {
					state.isLoadFailed = true;
				} finally {
					state.isLoading = false;
					state.lastUpdate = getLocalDateTimeString();
				}
			}

			async function onReload() {
				await fetchList();
			}

			function onWSMessage(event) {
				const data = JSON.parse(event.data);
				if (
					data.messageType == webSocketMessageTypes.SHOPEE_RELABEL_UPDATE
				) {
					state.operatorMap = data.data;
					state.lastUpdate = getLocalDateTimeString();
				}
			}

			onMounted(async () => {
				await fetchList();
				createWrapperWebSocket(
					$toast,
					websocketApis.sortationRealTimeWS,
					socketRef,
					onWSMessage
				);
			});

			return {
				state,
				onReload,
				orderBy: _.orderBy
			};
		},
	};
</script>

<style scoped>
	#not-found-container {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	#no-result-img {
		width: 100%vw;
		min-width: 400px;
		max-width: 800px;
	}
</style>