<template>
  <b-card
    no-body
    style="margin-bottom: 0px"
  >
    <b-card-header>
      <h2 class="mb-0">
        {{ operator }}
      </h2>
      <b-card-text> Last Update: {{ lastUpdate }} </b-card-text>
    </b-card-header>

    <b-container fluid>
      <b-row>
        <b-col cols="2">
          <div id="parcel-container">
            <b-media no-body>
              <b-media-aside class="mr-2 mt-1">
                <b-avatar
                  size="48"
                  variant="light-primary"
                >
                  <feather-icon
                    size="24"
                    icon="BoxIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h1 class="display-3 font-weight-bolder mb-0">
                  {{ formatNumber(data.countIn60Mins) }}
                </h1>
                <b-card-text class="font-small-3 mb-0">
                  Last 60 minutes parcels
                </b-card-text>
              </b-media-body>
            </b-media>

            <b-media no-body>
              <b-media-aside class="mr-2 mt-1">
                <b-avatar
                  size="48"
                  variant="light-primary"
                >
                  <feather-icon
                    size="24"
                    icon="BoxIcon"
                  />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h1 class="display-3 font-weight-bolder mb-0">
                  {{ formatNumber(data.totalParcel) }}
                </h1>
                <b-card-text class="font-small-3 mb-0">
                  Total parcels today
                </b-card-text>
              </b-media-body>
            </b-media>
          </div>
        </b-col>

        <b-col cols="10">
          <shopee-relabel-operator-hour-complete :data="data.countInHours" />
        </b-col>
      </b-row>

      <b-row class="text-center mx-0">
        <b-col
          cols="4"
          class="
            border-top border-right
            d-flex
            align-items-between
            flex-column
            py-1
          "
        >
          <b-card-text class="text-muted mb-0">
            Pending Tasks
          </b-card-text>
          <h1 class="font-weight-bolder mb-50">
            {{ formatNumber(data.pendingCount) }}
          </h1>
        </b-col>

        <b-col
          cols="4"
          class="
            border-top border-right
            d-flex
            align-items-between
            flex-column
            py-1
          "
        >
          <b-card-text class="text-muted mb-0">
            Ongoing Tasks
          </b-card-text>
          <h1 class="font-weight-bolder mb-50">
            {{ formatNumber(data.ongoingCount) }}
          </h1>
        </b-col>

        <b-col
          cols="4"
          class="border-top d-flex align-items-between flex-column py-1"
        >
          <b-card-text class="text-muted mb-0">
            Done Tasks
          </b-card-text>
          <h1 class="font-weight-bolder mb-50">
            {{ formatNumber(data.doneCount) }}
          </h1>
        </b-col>
      </b-row>
    </b-container>
  </b-card>
</template>

<script>
import { computed } from '@vue/composition-api'
import {
  BCard,
  BCardHeader,
  BRow,
  BCol,
  BCardText,
  BCardBody,
  BContainer,
  BMedia,
  BAvatar,
  BMediaAside,
  BMediaBody,
} from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'
import ShopeeRelabelOperatorHourComplete from './ShopeeRelabelOperatorHourComplete.vue'
import { formatNumber } from '@/utils/ui-helper'
import { getLocalDateTimeString } from '@/utils/date-time-helper';

export default {
  components: {
    BCard,
    BCardHeader,
    BRow,
    BCardText,
    BCol,
    BCardTtitle,
    BCardBody,
    BContainer,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    ShopeeRelabelOperatorHourComplete,
  },
  props: {
    operator: {
      type: String,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    lastUpdate: {
      type: String,
      required: true,
    },
  },
  setup(props, ctx) {
    return {
      formatNumber,
    }
  },
}
</script>

<style scoped>
#status-breakdown {
  margin-left: 30px;
}
#parcel-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 20px;
}
</style>
