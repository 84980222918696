<template>
	<simple-chart
		custom-style="height: 245px;"
		:options="option"
		:is-loading="false"
		:load-fail="false"
	/>
</template>

<script>
	import SimpleChart from "@common-components/chart/SimpleChart.vue";
	import { computed } from "@vue/composition-api";

	export default {
		components: {
			SimpleChart,
		},
		props: {
			data: {
				type: Array,
				required: true,
			},
		},
		setup(props, ctx) {
			const option = computed(() => {
				return {
					legend: {
						data: ["Current", "Past"],
					},
					xAxis: {
						type: "category",
						data: [
							"0",
							"1",
							"2",
							"3",
							"4",
							"5",
							"6",
							"7",
							"8",
							"9",
							"10",
							"11",
							"12",
							"13",
							"14",
							"15",
							"16",
							"17",
							"18",
							"19",
							"20",
							"21",
							"22",
							"23",
						],
					},
					yAxis: {
						type: "value",
					},
					series: [
						{
							data: props.data,
							type: "bar",
						},
					],
				};
			});

			return {
				option,
			};
		},
	};
</script>
